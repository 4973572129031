// 1: Initialize
@import "init";

// 2: Bootstrap framework includes
@import "../../node_modules/bootstrap/scss/bootstrap.scss";

// 3: Components
@import
"components/utilities/background",
"components/utilities/flex",
"components/utilities/opacity",
"components/utilities/borders",
"components/utilities/sizing",
"components/utilities/position",
"components/utilities/text",
"components/utilities/icon",
"components/burger-icon",
"components/card",
"components/wizard",
"components/type",
"components/breadcrumb",
"components/nav",
"components/alert",
"components/buttons",

"components/code",
"components/dropdown",
"components/forms",
"components/grid",
"components/modal",
"components/progress",
"components/table",
"components/popover",
"components/tooltip",
"components/accordion",
"components/datatable",
"components/bar-chart",
"components/checkbox",
"components/radio",
"components/switch",
"components/option",
"components/dialog",
"components/strikethrough",
"components/separator",
"components/image-input",
"components/label",
"components/loader",
"components/symbol",
"components/pulse",
"components/page-loader",
"components/quick-search",
"components/scrolltop",
"components/spinner",
"components/sticky-toolbar",
"components/svg-icon",
"components/svg",
"components/timeline",
"components/timeline-2",
"components/timeline-3",
"components/timeline-4",
"components/timeline-5",
"components/overlay",
// "components/demo",
"components/wave",
"components/list",
// "components/bullet",
"components/navi",
// "components/ribbon",
"components/toggle";;

// 5: Layout
@import "layout/init";

html{
    font-family: 'Poppins';
    scroll-behavior: smooth;
}
////////////////////////////////////////////////////////////////
///////////// DO NOT MODIFY ABOVE INCLUDE ORDER ////////////////
////////////////////////////////////////////////////////////////
