.selectons {
  overflow-x: hidden; //horizontal
}

.text-muted.title {
  font-weight: 700;
}

.last10card:hover {
  transform: scale(0.95);
}
.SportsBetting {
  color: #e12b80;
  font-weight: bold;
}

.Unibet {
  color: black;
  font-weight: bold;
}

.clock {
  color: white;
}

.head {
  color: black;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
}
.pointer:hover {
  cursor: pointer;
}
.track {
  color: #0f72b5 !important;
  font-weight: 700;
  font-size: 13px;
}

.text_blue {
  color: #0f72b5 !important;
  font-weight: 500;
  font-size: 11px;
}
.text_green {
  font-weight: 800;
  color: green !important;
}
.textwon {
  font-weight: 800;
  font-size: 12px;
  color: rgb(252, 179, 24);
}
.textLost {
  font-weight: 800;
  color: red;
}
.textSecond {
  font-weight: 800;
  color: rgb(9, 106, 179);
}
.textThird {
  font-weight: 800;
  color: rgb(139, 52, 191);
}
.box-shadow {
  box-shadow: 0 1.8px 6.2px rgba(0, 0, 0, 0.034),
    0 1.7px 6.3px rgba(0, 0, 0, 0.048), 0 1.5px 6px rgba(0, 0, 0, 0.06),
    // 0 22.3px 17.9px rgba(0, 0, 0, 0.072),
    // 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    // 0 100px 80px rgba(0, 0, 0, 0.12)
;
}
