.last10:hover {
  transform: scale(1.06);
  transition-duration: 1.1s;
  
  
}
/* .last10 {
  height: 100px;
  margin-bottom: 0.1cm;
} */

/* .odd:hover{
  animation:ease-in;
  transform: scale(1.5);
  transition: ease-in;
} */

.hr {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 202, 34, 0.75), rgba(0, 0, 0, 0));
  
}
